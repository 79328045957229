// src/pages/LegalStatement.js

import React from 'react';
import './LegalStatement.css'; // Ensure this CSS file exists or reuse TermsOfUse.css

const sections = [
  {
    title: 'Introduction',
    content: (
      <p>
        This Legal Statement sets out the legal terms under which Epsilon Hellas (Overseas) Ltd operates this website and provides its services. By accessing this website, you agree to comply with these legal terms.
      </p>
    ),
  },
  {
    title: 'Intellectual Property',
    content: (
      <p>
        All content displayed on this website, including text, images, logos, and trademarks, are the intellectual property of Epsilon Hellas or third-party licensors. Unauthorized use, reproduction, or distribution of this content is strictly prohibited without prior written consent.
      </p>
    ),
  },
  {
    title: 'Limitation of Liability',
    content: (
      <p>
        Epsilon Hellas (Overseas) Ltd is not responsible for any damages arising out of the use or inability to use this website or the information it contains. While we strive to provide accurate information, we do not guarantee the completeness or accuracy of the content provided.
      </p>
    ),
  },
  {
    title: 'Applicable Law',
    content: (
      <p>
        This Legal Statement and all issues regarding this website shall be governed by and construed in accordance with the laws of Cyprus, without regard to its conflict of law provisions.
      </p>
    ),
  },
  {
    title: 'Changes to This Legal Statement',
    content: (
      <p>
        Epsilon Hellas reserves the right to modify this Legal Statement at any time. Changes will be posted on this page, and we encourage you to review this page periodically for any updates.
      </p>
    ),
  },
  {
    title: 'Contact Us',
    content: (
      <p>
        If you have any questions regarding this Legal Statement, please <a href="https://wfy24.com/contact">contact us here</a>.
      </p>
    ),
  },
];

const BackToTop = () => (
  <div className="back-to-top">
    <button
      className="btn btn-primary"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      Back to Top
    </button>
  </div>
);

const LegalStatement = () => {
  return (
    <div className="legal-statement-container">
      <header className="legal-header mb-5">
        <h1>Legal Statement</h1>
      </header>

      <section className="legal-content">
        {sections.map((section, index) => (
          <article key={index} className="legal-section mb-5">
            <h2>{section.title}</h2>
            {section.content}
          </article>
        ))}
      </section>

      <BackToTop />
    </div>
  );
};

export default LegalStatement;
