import React, { useState } from 'react';
import useAxios from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.username || !credentials.password) {
      setError('Please fill in both fields');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const response = await axiosInstance.post('/api/login/', credentials);  // Use /api/login/
      // Save token to localStorage or context
      localStorage.setItem('token', response.data.token);
      // Redirect to dashboard
      navigate('/dashboard');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError('Invalid credentials');
      } else {
        setError('An error occurred. Please try again later.');
        console.error('Error during login:', err.response || err);  // Log error details
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center">Login</h2>
              {error && <p className="text-danger text-center">{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={credentials.username}
                    onChange={handleChange}
                    placeholder="Enter username"
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
