import React from 'react';
import Services from '../../components/Services';
import Counters from '../../components/Counters';
import Carouzel from '../../components/Carouzel';
import HeroSection from '../../pages/Home/HeroSection'; // Import the new HeroSection
import './Home.css'; // Import CSS for the home page

function Home() {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <HeroSection />

      {/* Epsilon Group Introduction */}
      <div className="container mt-5 text-center">
        <h2 className="mb-4">Epsilon's Premier Maritime Solutions and Services</h2>
        <div className="mb-4">
          <hr className="mx-auto" style={{ width: "80px", height: "4px", backgroundColor: "#007bff" }} />
        </div>
        <p className="mb-5">
          Our services are expertly tailored to meet the distinct needs of our principals, offering unparalleled
          precision and customization. With an uncompromising dedication to quality and reliability, we provide
          an elite portfolio of maritime solutions designed to optimize performance and drive operational
          excellence. Through our innovative approach and deep industry expertise, we empower our clients to
          exceed expectations and set new benchmarks for success in the maritime sector.
        </p>
      </div>

      {/* Services Section */}
      <div className="container-fluid mt-5">
        <Services />
      </div>

      {/* Counters Section */}
      <div className="container-fluid mt-5 p-0">
        <Counters />
      </div>

      {/* Our Commitments Section */}
      <div className="container mt-5 text-center">
        <h2 className="mb-4">Our Commitments</h2>
        <div className="mb-4">
          <hr className="mx-auto" style={{ width: "80px", height: "4px", backgroundColor: "#007bff" }} />
        </div>
        <div className="mb-4"></div>

        {/* Carouzel Section */}
        <div className="container-fluid mt-5 pb-5">
          <Carouzel />
        </div>
      </div>
    </div>
  );
}

export default Home;
