// frontend/src/utils/axiosInstance.js
import axios from 'axios';

// Create a singleton Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://wfy24.com/api/', // Ensure this is correct
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;