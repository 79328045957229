import React from 'react';
import CountUp from 'react-countup'; // Import CountUp for number animation
import './AboutUs.css'; // Custom CSS for About Us page
import aboutImage from '../../assets/About.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const AboutUs = () => {
  return (
    <div className="about-us-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={aboutImage} alt="About Epsilon" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Driving Maritime Success Through People and Passion</h1>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">About Us</h2>
        <hr className="group-divider" />

        <p>
          Founded in 2001, Epsilon has built a proud legacy of excellence in the maritime industry, providing exceptional crew management solutions that are perfectly tailored to meet the diverse needs of our clients. Our journey began in 1976 with a passion for crewing excellence, and since then, we have grown into a leading provider of maritime services worldwide, connecting top seafarers with leading shipping companies across the globe.
        </p>

        <p>
          In 2006, we expanded our operations to include dedicated training centers, recognizing that the development of seafarers is the heart of our business. Today, we are honored to be a trusted partner in the maritime industry, managing over{' '}
          <strong>
            <CountUp start={1000} end={1100} duration={10} /> vessels
          </strong>
          , training more than{' '}
          <strong>
            <CountUp start={1500} end={2000} duration={10} /> seafarers monthly
          </strong>
          , and deploying over{' '}
          <strong>
            <CountUp start={6000} end={8200} duration={10} /> crew members
          </strong>{' '}
          on board at any given time.
        </p>

        <p>
          With 15 offices globally and a dedicated team of over{' '}
          <strong>
            <CountUp start={300} end={340} duration={3} /> professionals
          </strong>
          , Epsilon proudly serves over 170 corporate clients for training and 60 clients for crewing.
        </p>

        <p>
          We work with a diverse range of clients, including some of the world’s most prestigious and demanding shipping companies, with an unwavering commitment to excellence and a deep understanding of the needs of both shipowners and seafarers. Our comprehensive range of services includes recruitment, crew management and manning, pre-vetting, training services, technical support, and medical services, supported by our training centers and dedicated clinic for seafarer well-being.
        </p>

        <p>
          Epsilon operates branch offices and manning agencies around the world, along with training centers for seafarers and a dedicated clinic in Manila to ensure the health and safety of our crew members. Our services are designed to provide a holistic approach to maritime operations, encompassing crew management, training, technical support, and medical services.
        </p>

        <p>
          Epsilon has been recognized for its contributions to the maritime industry, proudly serving as a long-standing member of key maritime organizations. We are certified by leading industry bodies, ensuring our services meet the highest standards of quality, compliance, and safety.
        </p>

        <p>
          Our success is built upon an extensive value network that includes reputable shipping houses, fully-controlled offices across nine countries, and key institutional affiliations. This unmatched expertise and relational capital enable us to offer customized services and seamless solutions for our clients. We uphold the highest ethical standards, respect the well-being of our seafarers and employees, and are committed to the sustainability and future growth of our operations.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
