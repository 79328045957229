import React from 'react';
import './FactsAndFigures.css';
import factsImage from '../../assets/FactsAndFugures.webp'; // You can replace this with another relevant image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const FactsAndFigures = () => {
  return (
    <div className="facts-figures-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={factsImage} alt="Facts & Figures" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Your Trusted Partner in Maritime Excellence</h1>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Facts & Figures</h2>
        <hr className="group-divider" />

        <p>
          <strong>98% Principal Retention Rate:</strong> Despite the challenges presented by the global pandemic, Epsilon maintained a remarkable 98% retention rate among our principals, underscoring the trust and confidence our partners place in us.
        </p>
        <p>
          <strong>Global Partnerships and New Clients:</strong> We expanded our global partnerships and welcomed new clients, continuing to strengthen our position in the maritime industry.
        </p>
        <p>
          <strong>New Vessels:</strong> In 2021, we added 37 new vessels to our management portfolio, following the addition of 25 new vessels in 2020. This growth reflects our commitment to expanding our capabilities and providing high-quality services to our clients.
        </p>
        <p>
          <strong>Leadership in Institutional Bodies:</strong> Epsilon's leadership in institutional bodies such as the International Maritime Employers' Council (IMEC) further demonstrates our commitment to the maritime community and our role in shaping industry standards.
        </p>
        <p>
          <strong>Crew Development Programs:</strong> We launched several crew development programs, including Electrician, Cadetship, Higher License, and Cooks, to support the continuous growth and advancement of our seafarers.
        </p>
        <p>
          <strong>Tailor-Made and Online Courses:</strong> We developed customized courses such as Passage Planning with the use of ECDIS for Angelicoussis Group and Port State Control training, ensuring our crew members are equipped with the latest skills and knowledge.
        </p>
        <p>
          <strong>Awards and Recognition:</strong> Epsilon has received numerous awards and recognition for our contributions, including accolades for our Odessa Training Center and recognition from Safety4Sea for our commitment to safety and training excellence.
        </p>
      </div>
    </div>
  );
};

export default FactsAndFigures;
