import React, {useState} from 'react';
import './Carouzel.css'; // Import your custom CSS

// Import images
import manningServices from '../assets/Manning-services.webp';
import medicalServices from '../assets/Medical-services.webp';
import preVettingServices from '../assets/Pre-vetting-services.webp';
import technicalServices from '../assets/Technical-services.webp';
import trainingServices from '../assets/Training-services.webp';
import technologyServices from '../assets/Technology-services.webp';

const Carouzel = () => {
  const slides = [
    {
      title: "Excellence in Human Capital",
      description: "We place people at the core of what we do, ensuring a superior experience for all.",
      imageUrl: manningServices,
    },
    {
      title: "State-of-the-Art Training",
      description: "Enhancing skills and competencies with our cutting-edge training programs.",
      imageUrl: trainingServices,
    },
    {
      title: "Adaptive Management and Cutting-Edge Technologies",
      description: "Innovating maritime operations through modern management and technology.",
      imageUrl: technologyServices,
    },
    {
      title: "Commitment to Quality and Continuous Improvement",
      description: "We strive for excellence by focusing on continuous development and improvement.",
      imageUrl: technicalServices,
    },
    {
      title: "Regulatory Compliance and Operational Integrity",
      description: "Ensuring all operations comply with regulations and maintaining the highest integrity.",
      imageUrl: preVettingServices,
    },
    {
      title: "Developing People, Elevating Standards",
      description: "Empowering individuals while elevating the standards of the maritime industry.",
      imageUrl: medicalServices,
    },
    {
      title: "Shaping the Future of Maritime Labor",
      description: "Pioneering the future of maritime labor with innovation and expertise.",
      imageUrl: medicalServices,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  return (
      <div className="carouzel">
        <div
            className="slides-container"
            style={{transform: `translateX(-${currentIndex * 100}%)`}}
        >
          {slides.map((slide, index) => (
              <div className="slide" key={index}>
                <div className="text-content">
                  {/* Title will be displayed above the description */}
                  <h1>{slide.title}</h1>
                  <p>{slide.description}</p>
                </div>
                <div className="image-content">
                  <img src={slide.imageUrl} alt={slide.title}/>
                </div>
              </div>
          ))}
        </div>

        <button className="next" onClick={nextSlide}>
          Next
        </button>
      </div>
  );
};

export default Carouzel;