import React from 'react';
import PublicIcon from '@mui/icons-material/Public'; // Globe icon
import './LanguageSelector.css';

function LanguageSelector() {
  const [language, setLanguage] = React.useState('EN'); // Default language

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <div className="language-selector">
      <PublicIcon className="globe-icon" />
      {/* Display language options horizontally */}
      <div className="language-options">
        {['EN', 'ES', 'FR', 'DE', 'GR'].map((lang) => (
          <span
            key={lang}
            className={`language-option ${lang === language ? 'active' : ''}`}
            onClick={() => handleLanguageChange(lang)}
          >
            {lang}
          </span>
        ))}
      </div>
    </div>
  );
}

export default LanguageSelector;
