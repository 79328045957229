import React from 'react';
import './TheEpsilonVision.css'; // Custom CSS for the Vision page
import visionImage from '../../assets/Vision.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const TheEpsilonVision = () => {
  return (
    <div className="epsilon-vision-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={visionImage} alt="Vision" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Championing Safe and Efficient Maritime Operations</h1>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Our Mission & Vision</h2>
        <hr className="group-divider" />

        <h3>Our Mission</h3>
        <p>
          At Epsilon, our mission is to deliver reliable, efficient, and compliant maritime solutions that empower our clients and support the success of both shipping companies and seafarers. We are guided by principles of integrity, reliability, and customer focus, striving to build lasting partnerships founded on trust and professionalism.
        </p>

        <h3>Our Vision</h3>
        <p>
          Our vision is to be the leading provider of maritime services, recognized for our commitment to quality, safety, and operational excellence. We aim to enhance the efficiency, safety, and sustainability of our clients' operations while fostering the growth and well-being of our seafarers. Epsilon is dedicated to shaping the future of the maritime industry, driven by our core values of integrity, reliability, and an unwavering focus on our clients' needs.
        </p>
      </div>
    </div>
  );
};

export default TheEpsilonVision;
