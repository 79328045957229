import React from 'react';
import manningServices from '../assets/Manning-services.webp';
import medicalServices from '../assets/Medical-services.webp';
import preVettingServices from '../assets/Pre-vetting-services.webp';
import technicalServices from '../assets/Technical-services.webp';
import trainingServices from '../assets/Training-services.webp';
import technologyServices from '../assets/Technology-services.webp';
import './Services.css'; // Add this for styling

const Services = () => {
  return (
    <div className="container mt-5">
      <div className="row text-center">
        {/* Crew Management & Manning Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={manningServices}
              alt="Crew Management & Manning Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Crew Management & Manning Services</div>
            <div className="service-overlay">
              <p className="important-text">Crew management and manning solutions</p>
            </div>
          </div>
        </div>

        {/* Training Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={trainingServices}
              alt="Training Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Training Services</div>
            <div className="service-overlay">
              <p className="important-text">Enhance the skills of your crew</p>
            </div>
          </div>
        </div>

        {/* Pre-vetting Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={preVettingServices}
              alt="Pre-vetting Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Pre-vetting Services</div>
            <div className="service-overlay">
              <p className="important-text">Ensure compliance before inspections</p>
            </div>
          </div>
        </div>

        {/* Technical Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={technicalServices}
              alt="Technical Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Technical Services</div>
            <div className="service-overlay">
              <p className="important-text">Comprehensive technical management solutions</p>
            </div>
          </div>
        </div>

        {/* Medical Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={medicalServices}
              alt="Medical Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Medical Services</div>
            <div className="service-overlay">
              <p className="important-text">Healthcare solutions for your crew</p>
            </div>
          </div>
        </div>

        {/* Technology Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={technologyServices}
              alt="Technology Services"
              className="img-fluid"
              width="350"
              height="200"
            />
            <div className="service-title">Technology Services</div>
            <div className="service-overlay">
              <p className="important-text">Software, cloud, and IT solutions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
