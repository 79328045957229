import React, { useState, useEffect } from 'react';
import './LetsMeetUp.css'; // Import the CSS file

// ChatRoom Component
function ChatRoom({ roomName }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(`ws://localhost:8000/ws/chat/${roomName}/`);
    setSocket(ws);

    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      setMessages((prevMessages) => [...prevMessages, data.message]);
    };

    return () => ws.close(); // Clean up WebSocket on unmount
  }, [roomName]);

  const sendMessage = () => {
    if (newMessage.trim() !== '') {
      socket.send(JSON.stringify({ message: newMessage }));
      setNewMessage('');
    }
  };

  return (
    <div className="chat-container">
      <h2>Epsilon Communication Chat</h2>
      <p className="welcome-message">
        We believe that the best way to build strong partnerships is by meeting face-to-face.
        Whether you're interested in our services, exploring potential collaborations, or simply
        want to know more about our company, we would love to connect with you.
        <br /><br />
        Feel free to reach out to us to schedule a meeting at one of our many global offices or
        virtually, at your convenience. Our team is always ready to discuss how we can support
        your maritime operations and address your specific needs.
      </p>
      <div className="message-box">
        {messages.map((msg, index) => (
          <div key={index} className="message">{msg}</div>
        ))}
      </div>
      <div className="input-box">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default ChatRoom;
