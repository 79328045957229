// src/pages/Countries/Ukraine.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Ukraine.css'; // Custom CSS for Ukraine page
import ukraineImage from '../../assets/Ukraine.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Ukraine = () => {
  return (
    <div className="ukraine-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={ukraineImage} alt="Epsilon in Ukraine" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">From Ukraine’s Ports</h1>
            <h2>Steering Maritime Innovation and Expertise</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Ukraine</h2>
        <hr className="group-divider" />

        <p>
          Ukraine stands as a distinguished maritime nation, deeply connected to the sea. Ukrainian seafarers are highly regarded for their
          resilience and perseverance, making them the preferred crew for some of the world’s most reputable Principals.
        </p>

        <p>
          Epsilon has maintained a strong and enduring presence in the Ukrainian market. Our office, strategically located in the major port city
          of Odessa, oversees a pool of several thousand officers and ratings, who serve a significant portion of our global clientele. In addition,
          we are committed to the continuous enhancement of our seafarers' skills through our state-of-the-art training center in Odessa, ensuring
          they remain at the forefront of the maritime industry’s evolving demands.
        </p>
      </div>

      {/* Professional Contact Sections */}
      <div className="ukraine-contact container mt-5">
        <h3 className="text-center">Contact EPSILON MARITIME SERVICES LTD</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Frantsuz’ky Blvd, 54/23, Odesa, Odes’ka oblast, Ukraine, 65000</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+380487280062">+380 48 728 00 62</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:crew@epsilonhellas.com.ua">crew@epsilonhellas.com.ua</a></p>
              </div>
            </div>
          </div>
        </div>

        {/* EPSILON MARITIME TRAINING AND EDUCATIONAL CENTER */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>EPSILON MARITIME TRAINING AND EDUCATIONAL CENTER</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Frantsuz’ky Blvd, 54/23, Odesa, Odes’ka oblast, Ukraine, 65000</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+380487280062">+380 48 728 00 62</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:training@epsilonhellas.com.ua">training@epsilonhellas.com.ua</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ukraine;
