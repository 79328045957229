import React, { useState, useEffect } from 'react';
import hooverShip from '../../assets/Home.webp'; // Import the image
import './HeroSection.css'; // Import the CSS file for the hero section

function HeroSection() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // To track if the image is hovered

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 200; // Adjust this value based on when you want the effect to trigger
      if (scrollTop > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup listener on unmount
    };
  }, []);

  return (
    <div className={`hero-section ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid p-0">
        <div
          className="position-relative text-center text-white"
          onMouseEnter={() => setIsHovered(true)} // Set hover to true
          onMouseLeave={() => setIsHovered(false)} // Set hover to false
        >
          <img src={hooverShip} alt="Hoover Ship" className={`img-fluid w-100 ${isScrolled ? 'grayscale' : ''}`} />
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-10">Welcome to Epsilon</h1>
            <p className="lead">
              {isHovered
                ? "Founded in 2001, Epsilon represents a legacy of maritime excellence, delivering unparalleled crew management solutions. We are proud to be an elite provider, offering a comprehensive suite of services including recruitment, crew management, manning, pre-vetting, and training, supported by dedicated health services to ensure the well-being of seafarers. With our cutting-edge technology and a commitment to exceptional service, Epsilon stands as a trusted partner for some of the world's most demanding shipping companies worldwide. Discover how our professionalism and expertise can elevate your maritime operations on our website."
                : "Innovating Today, Leading Tomorrow in Maritime Solutions"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
