import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import JobApplyButton from './components/JobApplyButton';
import Login from './components/Login';
import LogoutButton from './components/LogoutButton';
import Dashboard from './dashboard/Dashboard';
import Home from './pages/Home/Home';
import LetsMeetUp from './pages/LetsMeetUp/LetsMeetUp';
import PrivacyNotice from './pages/PrivacyNotice/PrivacyNotice';
import DataProtectionPolicy from './pages/DataProtectionPolicy/DataProtectionPolicy';
import TermsOfUse from './pages/TermOfUse/TermsOfUse';
import CookieStatement from './pages/CookieStatement/CookieStatement';
import LegalStatement from './pages/LegalStatement/LegalStatement';
import Services from './components/Services';
import News from './pages/News/News';
import NewsDetail from './pages/NewsDetail/NewsDetail'; // Import NewsDetail component

// Import for footer links
import AboutUs from './pages/About/AboutUs';
import TheEpsilonVision from './pages/TheEpsilonVision/TheEpsilonVision';
import TheEpsilonTeam from './pages/TheEpsilonTeam/TheEpsilonTeam';
import OurGlobalPresence from './pages/GlobalPresence/OurGlobalPresence';
import FactsFigures from './pages/FactsAndFigures/FactsAndFigures';
import CompanySustainability from './pages/CompanySustainability/CompanySustainability';

// Import the RND page
import RND from './pages/RND/RND';

// Import country-specific pages
import ThePhilippines from './pages/Countries/ThePhilippines'; // Correct import
import Ukraine from './pages/Countries/Ukraine';
import Russia from './pages/Countries/Russia';
import Romania from './pages/Countries/Romania';
import Turkey from './pages/Countries/Turkey';
import Greece from './pages/Countries/Greece';
import Cyprus from './pages/Countries/Cyprus';
import Indonesia from './pages/Countries/Indonesia';
import Vietnam from './pages/Countries/Vietnam';
import Georgia from './pages/Countries/Georgia';

function App() {
  return (
    <Router>
      <div className="App d-flex flex-column min-vh-100">
        <Header />
        <div className="job-apply-container">
          <JobApplyButton />
        </div>
        <main className="flex-fill">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<LogoutButton />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contact" element={<LetsMeetUp />} />
            <Route path="/privacy" element={<PrivacyNotice />} />
            <Route path="/data-protection" element={<DataProtectionPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/cookie" element={<CookieStatement />} />
            <Route path="/legal" element={<LegalStatement />} />
            <Route path="/services" element={<Services />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:slug" element={<NewsDetail />} /> {/* Dynamic Route for articles using slug */}

            {/* Footer links */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/epsilon-vision" element={<TheEpsilonVision />} />
            <Route path="/epsilon-team" element={<TheEpsilonTeam />} />
            <Route path="/our-global-presence" element={<OurGlobalPresence />} />
            <Route path="/facts-figures" element={<FactsFigures />} />
            <Route path="/sustainability" element={<CompanySustainability />} />

            {/* RND page */}
            <Route path="/rnd" element={<RND />} />

            {/* Country-specific pages */}
            <Route path="/the-philippines" element={<ThePhilippines />} />
            <Route path="/ukraine" element={<Ukraine />} />
            <Route path="/russia" element={<Russia />} />
            <Route path="/romania" element={<Romania />} />
            <Route path="/turkey" element={<Turkey />} />
            <Route path="/greece" element={<Greece />} />
            <Route path="/cyprus" element={<Cyprus />} />
            <Route path="/indonesia" element={<Indonesia />} />
            <Route path="/vietnam" element={<Vietnam />} />
            <Route path="/georgia" element={<Georgia />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
