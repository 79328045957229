import React from 'react';
import './TheEpsilonTeam.css'; // Custom CSS for The Epsilon Team page
import teamImage from '../../assets/EpsilonTeam.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const TheEpsilonTeam = () => {
  return (
    <div className="epsilon-team-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={teamImage} alt="Epsilon Team" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Navigating Success with Integrity and Expertise</h1>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">The Epsilon Team</h2>
        <hr className="group-divider" />

        <p>
          Epsilon is a multinational company with a family ethos. Our team consists of experienced maritime professionals and is committed to excellence driven by a passion for the industry. The combination of maritime expertise, corporate ethos, industry insights, and innovative approaches is the key to delivering exceptional service. The collective experience and qualifications of our team are the reason why clients and seafarers trust us.
        </p>
        <p>
          At Epsilon, we believe that our people are the heart of our success. It is their passion, expertise, and dedication that drive us forward and help us deliver excellence to our clients every day. Our people are our greatest strength, and it is through their hard work and enthusiasm that we continue to navigate toward a brighter future for the maritime industry.
        </p>
      </div>
    </div>
  );
};

export default TheEpsilonTeam;
