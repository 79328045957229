import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './SearchBar.css';

function SearchBar() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`search-bar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      {!isExpanded && (
        <IconButton onClick={handleExpandClick} className="search-icon-btn">
          <SearchIcon className="search-icon" />
        </IconButton>
      )}
      {isExpanded && (
        <TextField
          variant="standard"
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="search-icon" />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          className="search-input"
          onBlur={() => setIsExpanded(false)} // Collapse the search bar when losing focus
          autoFocus // Focus the input when expanded
        />
      )}
    </div>
  );
}

export default SearchBar;
