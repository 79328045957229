import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import LanguageSelector from './LanguageSelector';
import SearchBar from './SearchBar';

function Menu() {
  const [isOpen, setIsOpen] = useState(false); // Manage open state
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false); // Manage submenu open state

  const toggleMenu = () => {
    console.log('Toggle Menu'); // Debugging to check if the function works
    setIsOpen(!isOpen); // Toggle menu open/close state
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen); // Toggle submenu
  };

  return (
    <>
      {/* Hamburger Icon for small screens */}
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Menu overlay (background when open) */}
      <div className={`menu-overlay ${isOpen ? 'open' : ''}`} onClick={toggleMenu}></div>

      {/* Menu panel (sliding menu) */}
      <div className={`menu-panel ${isOpen ? 'open' : ''}`}>
        <div className="close-button" onClick={toggleMenu}>
          &times;
        </div>

        {/* Language Selector */}
        <div className="menu-language">
          <LanguageSelector />
        </div>

        <hr className="menu-divider" />

        {/* Search Bar */}
        <div className="menu-search">
          <SearchBar />
        </div>

        <hr className="menu-divider" />

        {/* Navigation Links */}
        <ul className="menu-nav">
          <li>
            <Link to="/services" className="menu-link">Epsilon's Premier Maritime Solutions and Services</Link>
          </li>
          <li>
            <Link to="/news" className="menu-link">Epsilon Insights: News and Industry Events</Link>
          </li>
          <li>
            <Link to="/contact" className="menu-link">Let’s Work Together</Link>
          </li>
          <li>
            <span className="menu-link" onClick={toggleSubmenu}>
              Our Global Presence {isSubmenuOpen ? '▲' : '▼'}
            </span>
            {isSubmenuOpen && (
              <ul className="submenu">
                <li><Link to="/the-philippines" className="menu-link">The Philippines</Link></li>
                <li><Link to="/ukraine" className="menu-link">Ukraine</Link></li>
                <li><Link to="/russia" className="menu-link">Russia</Link></li>
                <li><Link to="/romania" className="menu-link">Romania</Link></li>
                <li><Link to="/turkey" className="menu-link">Turkey</Link></li>
                <li><Link to="/greece" className="menu-link">Greece</Link></li>
                <li><Link to="/cyprus" className="menu-link">Cyprus</Link></li>
                <li><Link to="/indonesia" className="menu-link">Indonesia</Link></li>
                <li><Link to="/vietnam" className="menu-link">Vietnam</Link></li>
                <li><Link to="/georgia" className="menu-link">Georgia</Link></li>
              </ul>
            )}
          </li>
        </ul>

        <hr className="menu-divider" />

        {/* Footer Links */}
        <ul className="menu-footer-nav">
          <li><Link to="/about-us" className="menu-link">The Epsilon Story</Link></li>
          <li><Link to="/epsilon-vision" className="menu-link">Discover our Vision</Link></li>
          <li><Link to="/epsilon-team" className="menu-link">The Epsilon Team</Link></li>
          <li><Link to="/facts-figures" className="menu-link">Defining Milestones in Maritime Excellence</Link></li>
          <li><Link to="/sustainability" className="menu-link">Vision for a Sustainable Maritime Future</Link></li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
