// JobApplyButton.js
import React from 'react';
import './JobApplyButton.css'; // Import the CSS file

function JobApplyButton() {
  return (
    <div className="job-apply-alert">
      <p className="apply-text">
        Your pathway to global maritime success
        <a href="https://wfy24.com/contact" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Join Epsilon today</button>
        </a>
      </p>
    </div>
  );
}

export default JobApplyButton;
