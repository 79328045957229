// src/pages/RND.js
import React from 'react';

const RND = () => {
  return (
    <div className="rnd-page">
      <h1>Designed by RND</h1>
      <p>This page provides information about RND's design work.</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default RND;
