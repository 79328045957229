// src/components/Dashboard.js
import React from 'react';
import LogoutButton from '../components/LogoutButton';

const Dashboard = () => {
  return (
    <div style={styles.container}>
      <h2>Dashboard</h2>
      <p>Welcome to your dashboard!</p>
      <LogoutButton />
    </div>
  );
};

// Simple inline styles for demonstration purposes
const styles = {
  container: {
    maxWidth: '600px',
    margin: '50px auto',
    padding: '20px',
    textAlign: 'center',
  },
};

export default Dashboard;