import React from 'react';
import './CompanySustainability.css'; // Custom CSS for Company Sustainability page
import sustainabilityImage from '../../assets/CompanySustainability.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const CompanySustainability = () => {
  return (
    <div className="sustainability-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={sustainabilityImage} alt="Sustainability" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Uniting People and Planet: Epsilon’s Path to a Sustainable Maritime Industry</h1>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Epsilon's Commitment to Sustainability and Corporate Social Responsibility</h2>
        <hr className="group-divider" />

        <p>
          At Epsilon, sustainability and corporate social responsibility (CSR) are integral to our mission, driving every aspect of our operations and decision-making processes. We recognize our responsibility not only to protect the marine environment but also to contribute meaningfully to the communities we serve. Our commitment is rooted in three core pillars: environmental stewardship, social responsibility, and economic resilience, ensuring that we foster sustainable growth, protect natural resources, and enrich the lives of those who work in and depend on the maritime industry.
        </p>

        <h3>Environmental Stewardship</h3>
        <p>
          We are dedicated to minimizing our environmental footprint through a range of initiatives aimed at reducing waste, optimizing energy consumption, and adopting paperless practices. By embracing innovative digital solutions and implementing energy-efficient technologies, we aim to create a more sustainable workplace that actively contributes to the preservation of our oceans. Our focus on operational excellence ensures that while we grow, we do so with respect for the environment, preserving its health for future generations.
        </p>

        <h3>Social Responsibility</h3>
        <p>
          At the heart of Epsilon’s CSR efforts is our unwavering dedication to the well-being of our seafarers, employees, and the wider maritime community. We invest in training and development programs designed to equip our people with the skills necessary to meet the evolving demands of the maritime industry. We are committed to fostering a safe, supportive, and inclusive work environment where every individual is treated fairly and given the tools to thrive. Our support extends beyond the workplace to include community development initiatives, healthcare, and education programs aimed at improving the quality of life for seafarers and their families.
        </p>

        <h3>Economic Resilience</h3>
        <p>
          Epsilon’s long-term commitment to sustainability extends to the economic vitality of both our business and the broader maritime sector. By prioritizing operational efficiency, innovation, and responsible resource management, we aim to deliver lasting value to our clients while contributing to the resilience and growth of the global maritime industry. This holistic approach ensures that we can meet today’s challenges while laying the groundwork for future prosperity.
        </p>

        <h3>Community Engagement</h3>
        <p>
          Our deep connection to the maritime community is reflected in our active involvement in industry events, forums, and educational initiatives. We proudly sponsor conferences and seminars that foster knowledge sharing and promote best practices, helping to shape the future of the shipping industry. Additionally, through training programs and workshops, we provide opportunities for skills development and career advancement for the next generation of maritime professionals.
        </p>

        <p>
          Epsilon’s community engagement efforts go beyond sponsorship and volunteering. We forge strong partnerships with local communities to drive meaningful impact, ensuring that those who live and work alongside us benefit from our presence. By fostering collaboration and supporting innovation, we strengthen the bonds within the maritime sector, ensuring a resilient, knowledgeable, and united community ready to meet the challenges of tomorrow.
        </p>

        <h3>A Vision for the Future</h3>
        <p>
          Through our integrated approach to sustainability and corporate social responsibility, Epsilon is not only contributing to the health of the maritime environment but also shaping a brighter future for the global maritime community. We believe that by embedding these principles into every facet of our operations, we can make a lasting and meaningful impact, safeguarding both people and planet for future generations.
        </p>
      </div>
    </div>
  );
};

export default CompanySustainability;
