// src/pages/NewsDetail.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import { stripHtml } from 'string-strip-html'; // Import the stripHtml function
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML

const NewsDetail = () => {
  const { slug } = useParams(); // Get slug from the URL
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const apiUrl = `${API_BASE_URL}/api/articles/news/${slug}/`;
        console.log('Fetching article from:', apiUrl); // For debugging

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch article, status: ${response.status}`);
        }
        const data = await response.json();
        setArticle(data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [API_BASE_URL, slug]);

  // Loading state
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-4">
        <div className="spinner-border" role="status" aria-hidden="true"></div>
        <span className="ms-2">Loading article...</span>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        {error}
      </div>
    );
  }

  // Ensure article is defined before rendering content
  if (!article) {
    return <p className="text-center">Article not found.</p>;
  }

  // Use article.content directly
  const content = article.content;

  // Sanitize the content to prevent XSS attacks
  const sanitizedContent = DOMPurify.sanitize(content);

  // Format the published date
  const formattedDate = article.published_date
    ? new Date(article.published_date).toLocaleDateString('en-GB')
    : 'Date not available';

  // Generate a plain text excerpt for meta tags
  const plainTextContent = stripHtml(content).result;
  const excerpt = article.meta_description || (plainTextContent ? plainTextContent.substring(0, 150) : '');

  return (
    <div className="container mt-5">
      {/* Meta tags for SEO */}
      <Helmet>
        <title>{article.meta_title || article.title} | Epsilon News</title>
        <meta name="description" content={excerpt} />
        <meta name="keywords" content={article.meta_keywords || 'default, keywords'} />
        <meta property="og:title" content={article.meta_title || article.title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={article.image || 'https://wfy24.com/default_image.jpg'} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://wfy24.com/news/${article.slug}`} />
      </Helmet>

      <h1 className="text-center mb-4">{article.title}</h1>
      {article.image && (
        <div className="text-center">
          <img src={article.image} alt={article.title} className="img-fluid mb-4" />
        </div>
      )}
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      ></div>
      <div className="text-muted">
        <small>Published on: {formattedDate}</small>
      </div>
    </div>
  );
};

export default NewsDetail;
