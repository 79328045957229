import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Ensure this file exists

const Footer = () => {
  return (
    <footer className="footer bg-light py-4">
      <div className="container">
        {/* Layer 1: Navigation Links (Placeholder, if needed later) */}
        <div className="row">
          <div className="col-12">
            <ul className="nav justify-content-center mb-3">
              {/* Navigation Links (Add if needed) */}
            </ul>
          </div>
        </div>

        {/* Layer 2 and 4: Company Information and Social Media */}
        <div className="row align-items-center mb-3">
          {/* Layer 2: Company Information */}
          <div className="col-md-6 text-center text-md-left">
            <p className="mb-0">©{new Date().getFullYear()} Epsilon Hellas (Overseas) Ltd, its subsidiaries and affiliates trading as 'Epsilon Group'. All rights reserved.</p>
          </div>

          {/* Layer 4: Social Media Buttons */}
          <div className="col-md-6 text-center text-md-right">
            <div className="social-buttons">
              <a
                href="https://www.facebook.com/epsiloncrew"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link mx-2"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link mx-2"
                aria-label="Twitter"
              >
                <i className="fab fa-x-twitter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/epsilon-hellas-crew-management-&-training/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link mx-2"
                aria-label="LinkedIn"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                href="https://www.instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link mx-2"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Layer 3: Legal Links and Designed by RND */}
        <div className="row">
          <div className="col-12 text-center">
            <p className="mb-2">Designed by <Link to="/rnd" className="rnd-link">RND</Link></p>
            <div className="d-flex justify-content-center flex-wrap">
              <Link to="/terms-of-use" className="legal-link mx-2">Terms of Use</Link>
              <span className="separator mx-1">|</span>
              <Link to="/privacy" className="legal-link mx-2">Privacy Notice</Link>
              <span className="separator mx-1">|</span>
              <Link to="/data-protection" className="legal-link mx-2">Data Protection</Link>
              <span className="separator mx-1">|</span>
              <Link to="/cookie" className="legal-link mx-2">Cookie Statement</Link>
              <span className="separator mx-1">|</span>
              <Link to="/legal" className="legal-link mx-2">Legal</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
