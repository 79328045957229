// src/pages/DataProtectionPolicy.js

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import './DataProtectionPolicy.css';

const sections = [
  {
    title: 'Epsilon Hellas Data Protection Policy Statement',
    id: 'policy-statement',
    content: (
      <p>
        Epsilon Hellas will follow procedures to ensure that all employees, contractors, agents, consultants, and other parties who have access to any personal information held by or on behalf of us are fully aware of and abide by their duties and responsibilities under the Act.
      </p>
    ),
  },
  {
    title: 'Statement of Policy',
    id: 'statement-of-policy',
    content: (
      <p>
        We need to collect and use information about people with whom we work in order to carry out our business and provide our services. These may include members of the public, current, past, and prospective employees, clients, customers, and suppliers. In addition, we may be required by law to collect and use information. All personal information, whether in paper, electronic, or any other format, must be handled and managed in accordance with the DPA.
      </p>
    ),
  },
  {
    title: 'Data Protection Principles',
    id: 'data-protection-principles',
    content: (
      <>
        <p>
          We fully support and comply with the EU General Data Protection Regulation (GDPR). In summary, this means personal information must be:
        </p>
        <ol type="i">
          <li>Processed fairly and lawfully and in a transparent manner;</li>
          <li>Collected for specified, explicit, and legitimate purposes;</li>
          <li>Adequate, relevant, and limited to what is necessary;</li>
          <li>Accurate and where necessary kept up to date;</li>
          <li>Processed in a manner that ensures appropriate security of the personal data.</li>
        </ol>
        <p>
          Accountability is central to GDPR. Data Controllers are responsible for compliance with the principles and must be able to demonstrate this to data subjects and the regulator.
        </p>
        <p>
          Our purpose for holding personal information, along with a description of the categories of people and organizations to which we may disclose it, are included in the Privacy Policy.
        </p>
      </>
    ),
  },
  {
    title: 'Collection and Use of Personal Data',
    id: 'collection-and-use-of-personal-data',
    content: (
      <>
        <p>Epsilon Hellas collects Personal Data relating to:</p>
        <ul>
          <li>Applicants for employment, full, part-time, and temporary employees;</li>
          <li>
            Applicants for seafarer employment, full, part-time, and temporary seafarers whom Epsilon Hellas may enter into employment contracts as agents only for and on behalf of ship owners and/or as employers;
          </li>
          <li>Contractors;</li>
          <li>Clients;</li>
          <li>Suppliers and other persons who provide goods and/or services to Epsilon Hellas;</li>
          <li>Users of our websites or other related services provided by Epsilon Hellas.</li>
        </ul>

        <p>Epsilon Hellas may hold and process the following types of Employee Personal Data and Seafarer Personal Data:</p>
        <ul>
          <li>
            <strong>Personal Details:</strong> Name, address and contact information, national identity/registry/insurance numbers, date of birth, gender, immigration status, and eligibility to work.
          </li>
          <li>
            <strong>Family Composition:</strong> Names of spouses and/or dependents and emergency contact details.
          </li>
          <li>
            <strong>Employment Details:</strong> CVs, recruitment details and application forms, job history and experience, references, qualifications, appraisals and performance ratings, promotions/demotions, training records, information related to an employment contract, working time records, and records relating to holiday and other leave, disciplinary actions, investigations or grievances, and workplace accidents.
          </li>
          <li>
            <strong>Medical Details;</strong>
          </li>
          <li>
            <strong>Financial Details:</strong> Including salary, bonuses, bank account numbers, pensions, and details of any company loans, contractual payment, and entitlements.
          </li>
          <li>
            <strong>Photographs of Individuals;</strong>
          </li>
        </ul>

        <p>
          The processing of Employee Personal Data and Seafarer Personal Data enables Epsilon Hellas to perform its role as an employer and/or agent, including fulfilling its legal obligations under applicable laws and as necessary in connection with the performance of employment contracts. Without this information, it would not be possible for Epsilon Hellas to perform a worker’s employment contract. Certain Employee Personal Data and Seafarer Personal Details are processed by Epsilon Hellas for its legitimate business interests, including:
        </p>
        <ul>
          <li>Administration and management of its employees;</li>
          <li>Recruitment and determining suitability for employment;</li>
          <li>Administering payroll services;</li>
          <li>Ensuring employee health and safety;</li>
          <li>Training;</li>
          <li>Ensuring legal and regulatory compliance, including monitoring compliance with internal rules and policies;</li>
          <li>Data backup, data archive, and document retention;</li>
          <li>Risk management, legal, accounting, and audit functions.</li>
        </ul>

        <p>
          Epsilon Hellas may also hold and process the following types of Sensitive Personal Data in relation to employees and seafarers:
        </p>
        <ul>
          <li>Racial or ethnic origin only where required;</li>
          <li>Health data where required by law and/or relating to benefits, accommodation of disabilities, leave entitlement, statutory sick pay, and/or health and safety at work;</li>
          <li>Criminal background data only where required.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Disclosure of Personal Information',
    id: 'disclosure-of-personal-information',
    content: (
      <>
        <p>
          Strict conditions apply to the disclosure of personal information both internally and externally. We will not disclose personal information to any third party unless we believe it is lawful to do so. Respect for confidentiality will be given where appropriate. In certain circumstances, information relating to staff acting in a business capacity may be made available provided that:
        </p>
        <ul>
          <li>We are required by law to do so;</li>
          <li>The processing is necessary for the performance of a public task that the Department has a statutory power to carry out;</li>
          <li>The member of staff has consented to the disclosure;</li>
          <li>The information is in a form that does not identify individual employees.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Handling of Personal Information',
    id: 'handling-of-personal-information',
    content: (
      <>
        <p>All our staff will, through appropriate training and responsible management:</p>
        <ul>
          <li>Fully observe conditions regarding the lawful and fair collection and use of personal information;</li>
          <li>Meet our legal obligations to specify the purposes for which personal information is gathered and used;</li>
          <li>Collect and process appropriate personal information only to the extent that it is needed to fulfill operational needs or to comply with any legal requirements;</li>
          <li>Ensure the accuracy and quality of personal information used;</li>
          <li>Where possible, pseudonymize or anonymize personal identifiers within information held;</li>
          <li>Apply strict checks to determine the length of time that personal information is held;</li>
          <li>Ensure that the rights of people about whom information is held can be fully exercised under the Act;</li>
          <li>Take appropriate technical and organizational security measures to safeguard personal information;</li>
          <li>Be responsible and able to demonstrate compliance with all of the above.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Compliance',
    id: 'compliance',
    content: (
      <>
        <p>Epsilon Hellas will ensure that:</p>
        <ul>
          <li>Our purposes for processing personal data are clearly set out in the Departmental Privacy Policy;</li>
          <li>Everyone managing and handling personal information understands that they are directly and personally responsible for following good Data Protection practice;</li>
          <li>Only staff who need access to personal information as part of their duties are authorized to do so;</li>
          <li>Everyone managing and handling personal information is appropriately trained to do so;</li>
          <li>Everyone managing and handling personal information is appropriately supervised;</li>
          <li>Anyone wanting to make enquiries about handling personal information knows what to do;</li>
          <li>Queries about handling personal information are promptly and courteously dealt with;</li>
          <li>Methods of handling personal information are clearly described;</li>
          <li>A review and audit is made of the way personal information is managed;</li>
          <li>Methods of handling personal information are regularly assessed and evaluated.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Staff Responsibilities',
    id: 'staff-responsibilities',
    content: (
      <>
        <p>
          All staff have a responsibility to protect the personal information held by the Company. They will take steps to ensure that personal data is kept secure at all times against unauthorized or unlawful loss or disclosure and in particular will ensure that:
        </p>
        <ul>
          <li>They are appropriately trained in the handling of personal information;</li>
          <li>Paper and electronic records or documents containing personal/sensitive data are kept securely;</li>
          <li>
            Personal data held on computers, mobile devices, and computer systems are protected by individual strong passwords which, where possible, have forced changes periodically. Access controls should also be placed on electronic records containing personal and sensitive information;
          </li>
          <li>
            If and when, as part of their responsibilities, staff collects information about other people, they must comply with the guidance set out in our Data Protection Policy. No one should disclose personal information outside this guidance or use personal data held about others for their own purposes.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Third Party Users of Personal Departmental Information',
    id: 'third-party-users',
    content: (
      <>
        <p>
          Any third parties who are users of personal information supplied by the Company will be required to confirm and demonstrate that they will abide by the requirements of the Act. There will be an expectation that these parties will audit their compliance with the DPA and will provide assurances to the Company in this respect.
        </p>
      </>
    ),
  },
  {
    title: 'Rights of Users',
    id: 'rights-of-users',
    content: (
      <>
        <p>
          According to the General Data Protection Regulation, you, as the data subject, have various rights in connection with the collection and processing of your personal data, which we would like to explain below.
        </p>
        <h3>Right to Confirmation and Access</h3>
        <p>
          Every data subject has the right to request from the controller a confirmation of whether personal data about him have been processed. If that is the case, the data subject also has the right to receive, free of charge, information about his personal data that has been stored as well as a copy of that information.
        </p>

        <h3>Right to Rectification</h3>
        <p>
          Every data subject has the right to request immediate rectification of personal data about him that is incorrect. Further, he has the right to request the completion of incomplete personal data — including by means of a supplementary statement — taking into account the purposes of the processing.
        </p>

        <h3>Right to Deletion</h3>
        <p>
          Every data subject has the right to request that the controller immediately remove personal data about him, if any of the following applies and if processing is not required:
        </p>
        <ul>
          <li>The personal data were collected or otherwise processed for purposes for which they are no longer necessary.</li>
          <li>The data subject lodges an objection to the processing and there are no legitimate grounds for the processing.</li>
          <li>The personal data were unlawfully processed.</li>
          <li>The person responsible is otherwise required by law to delete.</li>
        </ul>
        <p>
          Insofar as we, the controller, have made the personal data public and are required to delete them, we will take the appropriate steps, under consideration of the available technology and the costs of implementation, to inform other controllers who process the published personal data that the data subject has requested deletion. In addition, we will inform these other controllers that the data subject has requested the deletion of all links to these personal data, along with any copies or replications, to the extent that processing is not required.
        </p>

        <h3>Right to Restriction/Blocking of Processing</h3>
        <p>
          Every data subject has the right to request that the controller restrict the processing if one of the following conditions is met:
        </p>
        <ul>
          <li>The accuracy of the personal is contested by the data subject, for a period of time that allows the controller to verify the accuracy of the personal data.</li>
          <li>The processing is unlawful; the data subject rejects deletion of the personal data and demands instead that use of the personal data be restricted.</li>
          <li>The controller no longer requires the personal data for processing purposes, but the data subject requires them to assert, exercise or defend legal claims.</li>
          <li>The data subject has lodged an objection and it has not yet been determined whether the legitimate grounds of the controller outweigh those of the data subject.</li>
        </ul>

        <h3>Right to Object</h3>
        <p>
          Every data subject has the right, for reasons that arise from his particular situation, to object to the processing of his personal data, insofar as the processing is carried out to safeguard the legitimate interests of the controller or third parties.
        </p>
        <p>
          In the case of an objection, we will no longer process the personal data, unless we can demonstrate compelling legitimate grounds for processing that outweigh the interests, rights, and freedoms of the data subject, or the processing serves to assert, exercise or defend legal claims.
        </p>
        <p>
          If personal data are processed for the purpose of direct advertising, the data subject has the right to object at any time to the processing of his personal data for the purpose of such advertising. If the data subject objects to processing for direct advertising purposes, the personal data will no longer be processed for these purposes.
        </p>

        <h3>Right to Appeal</h3>
        <p>
          Independent of the above-stated rights and independent of other remedies, every data subject has the right to appeal to a supervisory authority if he believes that the processing of his personal data violates the data protection regulations.
        </p>
      </>
    ),
  },
  {
    title: 'Data Transfer',
    id: 'data-transfer',
    content: (
      <>
        <p>
          When transferring Personal Data to entities based in Third Countries, in its capacity as a Data Processor, Epsilon Hellas shall ensure that, prior to any such transfer:
        </p>
        <ul>
          <li>
            <strong>There is an adequate level of protection in place in respect of the Personal Data being transferred including the following:</strong>
            <ul>
              <li>
                Checking if the country of the recipient is one which has been approved by the European Commission as being an ‘adequate protection jurisdiction’ such that Personal Data may be transferred to that Third Country without any further safeguards being necessary;
              </li>
              <li>
                Ensuring that the Epsilon Hellas company transferring the Personal Data has a contract in place with such recipient that includes data protection obligations that meet the minimum requirements of the GDPR; or
              </li>
              <li>
                Ensuring that the recipient of Personal Data is Privacy Shield certified.
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Policy Awareness',
    id: 'policy-awareness',
    content: (
      <>
        <p>
          A copy of this policy statement will be given to all new members of staff and interested third parties. Existing staff and any relevant third parties will be advised of the policy which will be posted on our Internet and Intranet sites, as will any subsequent revisions. All staff and relevant third parties must be familiar with and comply with this policy at all times.
        </p>
      </>
    ),
  },
];

const BackToTop = () => (
  <div className="back-to-top">
    <Button variant="primary" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ArrowUp className="me-2" />
      Back to Top
    </Button>
  </div>
);

const DataProtectionPolicy = () => {
  return (
    <div className="data-protection-container">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8} className="main-content">
            <header className="policy-header mb-5">
              <h1>Data Protection Policy</h1>
            </header>

            <section className="policy-content">
              {sections.map((section) => (
                <article key={section.id} id={section.id} className="policy-section mb-5">
                  <h2>{section.title}</h2>
                  {section.content}
                </article>
              ))}
            </section>

            <BackToTop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataProtectionPolicy;
