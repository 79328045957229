// src/pages/PrivacyNotice.js

import React from 'react';
import './PrivacyNotice.css'; // Ensure your CSS is updated accordingly

const sections = [
  {
    title: 'Introduction',
    content: (
      <p>
        This privacy policy (the “Policy”) explains how Epsilon Hellas LTD and its subsidiaries and/or affiliates use the information provided when you use this site.
      </p>
    ),
  },
  {
    title: 'This Policy',
    content: (
      <ul>
        <li>Forms part of Epsilon Hellas Data Protection Policy.</li>
        <li>Applies to all users of this site.</li>
        <li>
          May be amended by Epsilon Hellas at any time, consistent with the requirements of applicable laws and regulations. Any revisions will take effect from the date on which the amended Policy is published, as indicated in the version number set out herein.
        </li>
      </ul>
    ),
  },
  {
    title: 'Definitions',
    content: (
      <p>
        “Personal Data” is as defined in the Data Protection Policy.<br />
        “Sensitive Personal Data” is as defined in the Data Protection Policy.<br />
        Words denoting the singular shall include the plural and vice versa.<br />
        Unless otherwise stated, all defined terms have the same meaning as defined in the Data Protection Policy.
      </p>
    ),
  },
  {
    title: 'Information Collected',
    content: (
      <>
        <p>
          When using this site, we may ask you for Personal Data, for example, your name, address or telephone number, email address to provide you with the services you wish to make use of. We also collect Personal Data when you voluntarily complete application forms or contact forms.
        </p>
        <p>
          Unless otherwise agreed with you, we will not collect any Sensitive Personal Data.
        </p>
        <p>
          Where Personal Data is not obtained directly from you but from a third party, the categories of such Personal Data may include individuals’ contact details, educational background, financial and pay details, details of certificates and diplomas, education and skills, marital status, nationality, job title, and CV.
        </p>
        <p>
          Cookies may also collect information about your use of this site or other sites accessible from our website.
        </p>
      </>
    ),
  },
  {
    title: 'Basis for Processing',
    content: (
      <p>
        The basis of our processing of Personal Data is set out in the Data Protection Policy.
      </p>
    ),
  },
  {
    title: 'Use of Your Personal Data',
    content: (
      <>
        <p>We use your Personal Data for purposes, including without limitation, the following:</p>
        <ul>
          <li>To facilitate your use of the site and our services, e.g., personalizing repeat visits;</li>
          <li>To help us analyze people’s use of our site to improve the user experience;</li>
          <li>
            If you explicitly consent, we may pass on your Personal Data within Epsilon Hellas so they may offer you their products and services or to third parties;
          </li>
          <li>
            In providing our services, we may send your details to, and also use information from, fraud prevention agencies.
          </li>
        </ul>
        <p>
          We will only collect and use Personal Data in accordance with applicable data protection laws.
        </p>
      </>
    ),
  },
  {
    title: 'Your Rights',
    content: (
      <p>
        For more information on your rights, please see the Data Protection Policy.
      </p>
    ),
  },
  {
    title: 'Data Transfers',
    content: (
      <p>
        We may transfer data outside of the EEA. For further information on the adequate safeguards adopted by us for the international transfer of Personal Data, please see the Data Protection Policy.
      </p>
    ),
  },
  {
    title: 'Cookies',
    content: (
      <>
        <p>
          Cookies are small text files placed on your computer by websites you visit to help personalize the site for your use and to improve site functionality. Most browsers allow you to turn off cookies. Switching off cookies may restrict your use of a website. By using our website, you agree that we can place the types of cookies referred to above on your device.
        </p>
        <p>We use the following cookies on our website:</p>
        <ul>
          <li>
            <strong>Session cookies:</strong>
            <p>Created only for the time of your visit and are deleted when you leave the website.</p>
          </li>
          <li>
            <strong>Persistent cookies:</strong>
            <p>
              Remain on your device for a period of time specified in the cookie and are activated each time you visit the website so it remembers who you are when you return.
            </p>
          </li>
          <li>
            <strong>Strictly necessary cookies:</strong>
            <p>Essential to enable you to navigate the website and use its features.</p>
          </li>
          <li>
            <strong>Performance cookies:</strong>
            <p>
              Used to collect information about how visitors use our website to help us improve it. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'Other Websites',
    content: (
      <p>
        Our website may contain links to other websites which are outside our control and are not covered by this Policy. If you access other sites using the links provided, the operators of these sites may collect information from you which will be used by them in accordance with their privacy policy, which may differ from ours. We do not accept any responsibility or liability for the privacy practices of such third-party websites and your use of such websites is at your own risk.
      </p>
    ),
  },
  {
    title: 'Contact',
    content: (
      <p>
        If you have any comments or queries regarding this site, please <a href="https://wfy24.com/contact">contact us here</a>. If you are not satisfied with our response or believe we are processing your Personal Data not in accordance with the law, you can complain to the relevant supervisory authority.
      </p>
    ),
  },
];

const PrivacyNotice = () => {
  return (
    <div className="privacy-notice-container">
      <header className="privacy-header">
        <h1>Privacy Policy</h1>
      </header>

      <section className="privacy-content">
        {sections.map((section, index) => (
          <article key={index} className="privacy-section">
            <h2>{section.title.toUpperCase()}</h2>
            {section.content}
          </article>
        ))}
      </section>
    </div>
  );
};

export default PrivacyNotice;
