import React from 'react';
import './Header.css';
import Logo from './Logo'; // Import the Logo component
import MyEpsilonButton from './MyEpsilonButton'; // Import MyEpsilonButton component
import Menu from './Menu'; // Import the Menu component

function Header() {
  return (
    <header className="App-header">
      <div className="d-flex align-items-center col-lg-2 col-md-3 col-sm-4">
        <Logo />
      </div>

      {/* Right-side content */}
      <div className="right-side">
        <MyEpsilonButton className="myEpsilonButton" />
        <Menu /> {/* Menu will handle the hamburger and the sliding panel */}
      </div>
    </header>
  );
}

export default Header;
